@import url('https://fonts.googleapis.com/css2?family=Coda:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&display=swap');



@media (min-width: 320px) and (max-width: 374px) {
    
        
    .motherDisp{
        box-sizing: border-box;
      
        
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 876vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:5vw;
        width : 68vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        color: #00f7ff;
        font-size: 1.4rem;
        text-align: center;
        
       
        
       
    }
    .webName p{
        margin: 0;
       
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }
    .navbarMobile{
       
        background-color: #07c3e9;
        color: #000000;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;
        
        top: 94vh;
        left : 0vw;
        width: 100vw;
        height: 6vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        z-index: 10;
    }
    .navbarMobile div{
        cursor: pointer;
    }
    .navbarMobile div p{
        font-style: normal;
        color: #000000;
        font-size: 1.2rem;
        margin: 0;
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 45vh;
        left: 12vw;
        width: 66vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 9vh;
        left: 9.5vw;
        width: 72vw;
        height: 18vh;
        text-align: justify;
        

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
     
        font-size: 1.3rem;
        
        
        color: #00f7ff;
        text-align: center;
       
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 4vh;
        left: 9vw;
        width: 81vw;
        height: 42vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 0.75rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1rem !important;
        
        color: #00f7ff;
        text-align: start;
        word-spacing: 0.25rem;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        color: #00f7ff;
        
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: -6.5vh;
        height: 41vh;
        width: 65vw;
        left: 9vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 219vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        cursor: pointer;
        width: 75vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 2rem;
        backface-visibility: hidden;
        

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.3rem;
        margin: 0;
        word-spacing: 0.1rem;

        color: #000000 !important;
        background-color: #00f7ff!important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
       

    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.3rem;
        margin: 0;
        color: #00f7ff;
        
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 17vh;
        position: relative;
        top: 16vh;
        left: 14vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        color: #00f7ff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;

        
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 20vh;
        height: 41vh;
        width: 65vw;
        left: 11.5vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 117vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 13vh;
        left: 10vw;
        width: 80vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 0.75rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 22vh;
        height: 37vh;
        left: 11.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 4rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 110vh;
        display: flex;
        justify-content: center;
        align-items: start;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        align-items: center;
        width: 78vw;
        height: 93vh;
    }
    .colLoc-Details{
        
        padding: 0;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 0;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.4rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 0;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.6rem;

    }

    .bigDispImgBot{
        width: 27% !important;
    }




}


@media (min-width: 375px) and (max-width: 389px) {

    
    .motherDisp{
        box-sizing: border-box;
      
       
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 872vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:5vw;
        width : 68vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        color: #00f7ff;
        font-size: 1.4rem;
        text-align: center;
        
        
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }
    .navbarMobile{
        background-color: #07c3e9;
        color: #000000;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;

        top: 94vh;
        left : 0vw;
        width: 100vw;
        height: 6vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        z-index: 10;
    }
    .navbarMobile div{
        cursor: pointer;
    }
    .navbarMobile div p{
        font-style: normal;
        color: #000000;
        font-size: 1.2rem;
        margin: 0;
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 45vh;
        left: 13vw;
        width: 66vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 11vh;
        left: 10.5vw;
        width: 72vw;
        height: 18vh;
        text-align: justify;
        

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        
        font-size: 1.4rem;
        
        
        color: #00f7ff;
        text-align: center;
      
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 4vh;
        left: 9vw;
        width: 81vw;
        height: 42vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 0.75rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1rem !important;
        color: #00f7ff;
        text-align: start;
        word-spacing: 0.25rem;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: -6.5vh;
        height: 41vh;
        width: 65vw;
        left: 9vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 215vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        cursor: pointer;
        width: 75vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 2rem;
        backface-visibility: hidden;
       

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.3rem;
        margin: 0;
        word-spacing: 0.1rem;
        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;

       
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.3rem;
        margin: 0;

        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 17vh;
        position: relative;
        top: 16vh;
        left: 14vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;

        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 20vh;
        height: 41vh;
        width: 65vw;
        left: 11.5vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 117vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 13vh;
        left: 10vw;
        width: 80vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 0.75rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 22vh;
        height: 37vh;
        left: 12.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 4rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
      
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 110vh;
        display: flex;
        justify-content: center;
        align-items: start;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        align-items: center;
        width: 78vw;
        height: 93vh;
    }
    .colLoc-Details{
        
        padding: 0;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 0;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.4rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 0;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.6rem;

    }

    .bigDispImgBot{
        width: 27% !important;
    }





}



@media (min-width: 390px) and (max-width: 392px){


    .motherDisp{
        box-sizing: border-box;
      
       
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 906vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:5vw;
        width : 68vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
      
        font-size: 1.5rem;
        text-align: center;
        
        color: #00f7ff;
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }
    .navbarMobile{
        background-color: #07c3e9;
        color: #000000;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;

        top: 94vh;
        left : 0vw;
        width: 100vw;
        height: 6vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        z-index: 10;
    }
    .navbarMobile div{
        cursor: pointer;
    }
    .navbarMobile div p{
        font-style: normal;
        color: #000000;
        font-size: 1.2rem;
        margin: 0;
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 47vh;
        left: 11vw;
        width: 68vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 12vh;
        left: 10.5vw;
        width: 72vw;
        height: 18vh;
        text-align: start;
       

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        
        font-size: 1.5rem;
        
        color: #00f7ff;
        text-align: center;
      
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 4vh;
        left: 9vw;
        width: 81vw;
        height: 42vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 0.8rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem !important;
        color: #00f7ff;
        text-align: start;
        word-spacing: 0.25rem;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: -7vh;
        height: 41vh;
        width: 65vw;
        left: 9vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 230vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 75vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 2rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;
        word-spacing: 0.1rem;

        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;

        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 17vh;
        position: relative;
        top: 16vh;
        left: 14vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 20vh;
        height: 41vh;
        width: 65vw;
        left: 11.5vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 136vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 22vh;
        left: 10vw;
        width: 80vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 0.9rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 40vh;
        height: 39vh;
        left: 11.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 4rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
      
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3.2rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 110vh;
        display: flex;
        justify-content: center;
        align-items: start;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        align-items: center;
        width: 78vw;
        height: 93vh;
    }
    .colLoc-Details{
        
        padding: 0;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 0;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 0;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.75rem;

    }

    .bigDispImgBot{
        width: 28% !important;
    }




}



@media (min-width: 393px) and (max-width: 411px){

    .motherDisp{
        box-sizing: border-box;
      
        
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 898vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:5vw;
        width : 68vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        color: #00f7ff;
        font-size: 1.5rem;
        text-align: center;
        
       
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }
    .navbarMobile{
        background-color: #07c3e9;
        color: #000000;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;

        top: 94vh;
        left : 0vw;
        width: 100vw;
        height: 6vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        z-index: 10;
    }
    .navbarMobile div{
        cursor: pointer;
    }
    .navbarMobile div p{
        font-style: normal;
        color: #000000;
        font-size: 1.2rem;
        margin: 0;
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 46vh;
        left: 12vw;
        width: 68vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 12vh;
        left: 11.5vw;
        width: 72vw;
        height: 18vh;
        text-align: start;
       

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
     
        font-size: 1.5rem;
        text-align: center;
        color: #00f7ff;
      
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 3vh;
        left: 9vw;
        width: 81vw;
        height: 42vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 0.8rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem !important;
        color: #00f7ff;
        text-align: start;
        word-spacing: 0.25rem;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: -6vh;
        height: 42.5vh;
        width: 65vw;
        left: 9vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 222vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 75vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 2rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;
        word-spacing: 0.1rem;

        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        color: #00f7ff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;

        
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 17vh;
        position: relative;
        top: 16vh;
        left: 14vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;
        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 19vh;
        height: 42.5vh;
        width: 65vw;
        left: 11.5vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 136vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 20vh;
        left: 10vw;
        width: 80vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 0.9rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 38vh;
        height: 38vh;
        left: 11vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 4rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3.2rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 110vh;
        display: flex;
        justify-content: center;
        align-items: start;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        align-items: center;
        width: 78vw;
        height: 93vh;
    }
    .colLoc-Details{
        
        padding: 0;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 0;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 0;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.75rem;

    }

    .bigDispImgBot{
        width: 28% !important;
    }




}



@media (min-width: 412px) and (max-width: 413px){

                     
    .motherDisp{
        box-sizing: border-box;
      
      
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 877vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:2vw;
        width : 68vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        color: #00f7ff;
        font-size: 1.5rem;
        text-align: center;
        
        
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }
    .navbarMobile{
        background-color: #07c3e9;
        color: #000000;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;

        top: 94vh;
        left : 0vw;
        width: 100vw;
        height: 6vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        z-index: 10;
    }
    .navbarMobile div{
        cursor: pointer;
    }
    .navbarMobile div p{
        font-style: normal;
        color: #000000;
        font-size: 1.2rem;
        margin: 0;
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 45vh;
        left: 12vw;
        width: 68vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 11vh;
        left: 11.5vw;
        width: 72vw;
        height: 18vh;
        text-align: start;
        

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
     
        font-size: 1.5rem;
       
        color: #00f7ff;
        text-align: center;
      
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 3vh;
        left: 9vw;
        width: 81vw;
        height: 42vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 0.8rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem !important;
        color: #00f7ff;
        text-align: start;
        word-spacing: 0.25rem;
        
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: -4vh;
        height: 41.5vh;
        width: 64vw;
        left: 9vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 212vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 75vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 2rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;
        word-spacing: 0.1rem;

        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;

        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 17vh;
        position: relative;
        top: 16vh;
        left: 15.5vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 18vh;
        height: 41.5vh;
        width: 64vw;
        left: 11.5vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 125vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 17vh;
        left: 10vw;
        width: 80vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 0.9rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 31vh;
        height: 37vh;
        left: 11.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3.2rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 110vh;
        display: flex;
        justify-content: center;
        align-items: start;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        align-items: center;
        width: 78vw;
        height: 93vh;
    }
    .colLoc-Details{
        
        padding: 0;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 0;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 0;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.75rem;

    }

    .bigDispImgBot{
        width: 28% !important;
    }



}



@media (min-width: 414px) and (max-width: 429px){

                      
    .motherDisp{
        box-sizing: border-box;
      
   
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 877vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:2vw;
        width : 68vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        
        font-size: 1.5rem;
        text-align: center;
        
        color: #00f7ff;
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }
    .navbarMobile{
        background-color: #07c3e9;
        color: #000000;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;

        top: 94vh;
        left : 0vw;
        width: 100vw;
        height: 6vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        z-index: 10;
    }
    .navbarMobile div{
        cursor: pointer;
    }
    .navbarMobile div p{
        font-style: normal;
        color: #000000;
        font-size: 1.2rem;
        margin: 0;
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 45vh;
        left: 12vw;
        width: 68vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 12vh;
        left: 11.5vw;
        width: 72vw;
        height: 18vh;
        text-align: start;
        

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
   
        font-size: 1.5rem;
        
        color: #00f7ff;
        text-align: center;
        
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 3vh;
        left: 9vw;
        width: 81vw;
        height: 42vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 0.8rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem !important;
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: -4vh;
        height: 40.5vh;
        width: 64vw;
        left: 9vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 212vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 75vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 2rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;
        word-spacing: 0.1rem;

        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        color: #00f7ff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;

        
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 17vh;
        position: relative;
        top: 16vh;
        left: 15.5vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 19vh;
        height: 40.5vh;
        width: 64vw;
        left: 11.5vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 125vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 17vh;
        left: 10vw;
        width: 80vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 0.9rem;
    }
    .concernTxt-5{
        display: inline-block !important;
      
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 28vh;
        height: 40vh;
        left: 10.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3.2rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 110vh;
        display: flex;
        justify-content: center;
        align-items: start;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        align-items: center;
        width: 78vw;
        height: 93vh;
    }
    .colLoc-Details{
        
        padding: 0;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 0;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 0;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.75rem;

    }

    .bigDispImgBot{
        width: 28% !important;
    }





}


@media (min-width: 430px) and (max-width: 539px){

                   
    .motherDisp{
        box-sizing: border-box;
      
        
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 879vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:2vw;
        width : 68vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        color: #00f7ff;
        font-size: 1.5rem;
        text-align: center;
        
        
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }
    .navbarMobile{
        background-color: #07c3e9;
        color: #000000;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;

        top: 94vh;
        left : 0vw;
        width: 100vw;
        height: 6vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        z-index: 10;
    }
    .navbarMobile div{
        cursor: pointer;
    }
    .navbarMobile div p{
        font-style: normal;
        color: #000000;
        font-size: 1.2rem;
        margin: 0;
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 46vh;
        left: 12vw;
        width: 68vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 11vh;
        left: 10.5vw;
        width: 72vw;
        height: 18vh;
        text-align: start;
       

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
  
        font-size: 1.5rem;
        text-align: center;
        color: #00f7ff;
     
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 1vh;
        left: 9vw;
        width: 81vw;
        height: 42vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 0.8rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem !important;
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: -1vh;
        height: 45vh;
        width: 66vw;
        left: 9vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 220vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 75vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 3rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;
        word-spacing: 0.1rem;
        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;
        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 17vh;
        position: relative;
        top: 16vh;
        left: 11.5vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 16vh;
        height: 45vh;
        width: 66vw;
        left: 11.5vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 119vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 14vh;
        left: 10vw;
        width: 80vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 0.9rem;
    }
    .concernTxt-5{
        display: inline-block !important;
     
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 23vh;
        height: 37vh;
        left: 13.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 6rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3.2rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 110vh;
        display: flex;
        justify-content: center;
        align-items: start;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        align-items: center;
        width: 78vw;
        height: 93vh;
    }
    .colLoc-Details{
        
        padding: 0;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 0;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 0;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.75rem;

    }

    .bigDispImgBot{
        width: 28% !important;
    }




}



@media (min-width: 540px) and (max-width: 599px){

                
    .motherDisp{
        box-sizing: border-box;
      
   
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 915vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 57vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        
        font-size: 1.5rem;
        text-align: center;
        
        color: #00f7ff;
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }
    .navbarMobile{
        background-color: #07c3e9;
        color: #000000;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;

        top: 94vh;
        left : 0vw;
        width: 100vw;
        height: 6vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        z-index: 10;
    }
    .navbarMobile div{
        cursor: pointer;
    }
    .navbarMobile div p{
        font-style: normal;
        color: #000000;
        font-size: 1.2rem;
        margin: 0;
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 40vh;
        left: 20vw;
        width: 56vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 26vh;
        left: 11vw;
        width: 72vw;
        height: 18vh;
        text-align: center;
        

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
       
        font-size: 1.5rem;
        word-spacing: 0.7rem;
        letter-spacing: -0.10rem;
        color: #00f7ff;
       
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 1vh;
        left: 8vw;
        width: 84vw;
        height: 42vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 0.8rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem !important;
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: -2vh;
        height: 45vh;
        width: 44vw;
        left: 7.5vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 255vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 65vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 3rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;
        word-spacing: 0.1rem;

        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 0.35rem;
        padding-right: 0.35rem;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;

        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 17vh;
        position: relative;
        top: 16vh;
        left: 13.5vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 16vh;
        height: 45vh;
        width: 44vw;
        left: 7.5vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 115vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 12vh;
        left: 8.5vw;
        width: 83vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 0.8rem;
    }
    .concernTxt-5{
        display: inline-block !important;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 17vh;
        height: 41vh;
        left: 25vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 6rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 4rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 110vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 0;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 35vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.65rem;

    }

    .bigDispImgBot{
        width: 20% !important;
    }




}




@media (min-width: 600px) and (max-width: 767px){

            
    .motherDisp{
        box-sizing: border-box;
      
       
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 875vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 57vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        
        font-size: 1.8rem;
        text-align: center;
        
        color: #00f7ff;
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }
    .navbarMobile{
        background-color: #07c3e9;
        color: #000000;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;

        top: 94vh;
        left : 0vw;
        width: 100vw;
        height: 6vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        z-index: 10;
    }
    .navbarMobile div{
        cursor: pointer;
    }
    .navbarMobile div p{
        font-style: normal;
        color: #000000;
        font-size: 1.2rem;
        margin: 0;
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 39vh;
        left: 15vw;
        width: 65vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 26vh;
        left: 11vw;
        width: 72vw;
        height: 18vh;
        text-align: center;
        

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
       
        font-size: 1.7rem;
        
        color: #00f7ff;
        
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 1vh;
        left: 8vw;
        width: 84vw;
        height: 42vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 1rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.2rem !important;
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.8rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: -1.5vh;
        height: 43vh;
        width: 53vw;
        left: 6.5vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 225vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 65vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 3rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem;
        margin: 0;
        word-spacing: 0.1rem;

        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 0.35rem;
        padding-right: 0.35rem;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        color: #00f7ff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem;
        margin: 0;

        
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 17vh;
        position: relative;
        top: 16vh;
        left: 12.5vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.1rem;

        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 20vh;
        height: 43vh;
        width: 53vw;
        left: 6.5vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 120vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 12vh;
        left: 8.5vw;
        width: 83vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 1rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.8rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 17vh;
        height: 45vh;
        left: 17vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 6rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 4rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.8rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 0;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.8rem;

    }

    .bigDispImgBot{
        width: 22% !important;
    }




}





@media (min-width: 768px) and (max-width: 799px){

        
    .motherDisp{
        box-sizing: border-box;
      
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 865vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 57vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        color: #00f7ff;
        font-size: 2.4rem;
        text-align: center;
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 42vh;
        left: 22vw;
        width: 54vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 24vh;
        left: 12vw;
        width: 72vw;
        height: 18vh;
        text-align: center;
        

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
  
        font-size: 2.4rem;
        word-spacing: 0.7rem;
        letter-spacing: -0.10rem;
        color: #00f7ff;
       
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 1vh;
        left: 8vw;
        width: 85vw;
        height: 45vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 1.2rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem !important;
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        top: 2vh;
        height: 48vh;
        width: 50vw;
        left: 8vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 215vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 65vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 3rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem;
        margin: 0;
        word-spacing: 0.2rem;

        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 3.35rem;
        padding-right: 3.35rem;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem;
        margin: 0;

        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 26vh;
        position: relative;
        top: 16vh;
        left: 12.5vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;

        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 10vh;
        height: 48vh;
        width: 50vw;
        left: 8vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 120vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 14vh;
        left: 8.5vw;
        width: 83vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 1.2rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 23vh;
        height: 39vh;
        left: 25.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 5.5rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 2.4rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 4rem;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;

    }

    .bigDispImgBot{
        width: 20% !important;
    }




}




@media (min-width: 800px) and (max-width: 809px){

    
    .motherDisp{
        box-sizing: border-box;
      
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 810vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 57vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        
        font-size: 2.4rem;
        text-align: center;
        
        color: #00f7ff;
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 42vh;
        left: 16vw;
        width: 64vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 23vh;
        left: 12vw;
        width: 72vw;
        height: 18vh;
        text-align: center;
        

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
    
        font-size: 2.4rem;
        word-spacing: 0.7rem;
        letter-spacing: -0.10rem;
        color: #00f7ff;
       
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        bottom: 1vh;
        left: 8vw;
        width: 85vw;
        height: 45vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 1.2rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem !important;
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: 4vh;
        height: 52vh;
        width: 60vw;
        left: 8vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 180vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 65vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 3rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem;
        margin: 0;
        word-spacing: 0.2rem;

        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 4.35rem;
        padding-right: 4.35rem;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem;
        margin: 0;

        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 26vh;
        position: relative;
        top: 16vh;
        left: 12.5vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;

        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 7vh;
        height: 52vh;
        width: 60vw;
        left: 8vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 6vh;
        left: 8.5vw;
        width: 83vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 1.2rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 8vh;
        height: 37vh;
        left: 22.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 5.5rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 2.4rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 4rem;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;

    }

    .bigDispImgBot{
        width: 20% !important;
    }




}




@media (min-width: 810px) and (max-width: 899px){

    
    .motherDisp{
        box-sizing: border-box;
      
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 842vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 57vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        color: #00f7ff;
        font-size: 2.4rem;
        text-align: center;
        
       
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 42vh;
        left: 20vw;
        width: 55vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 25vh;
        left: 12vw;
        width: 72vw;
        height: 18vh;
        text-align: center;
        

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
   
        font-size: 2.2rem;
        
        color: #00f7ff;
        
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        bottom: 1vh;
        left: 8vw;
        width: 85vw;
        height: 45vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 1.2rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem !important;
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: 2vh;
        height: 52vh;
        width: 55vw;
        left: 8vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 195vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 65vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 3rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.65rem;
        margin: 0;
        word-spacing: 0.2rem;

        
        color: #000000 !important;
        background-color: #00f7ff !important;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 3.35rem;
        padding-right: 3.35rem;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.65rem;
        margin: 0;

        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 26vh;
        position: relative;
        top: 16vh;
        left: 12.5vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
        color: #00f7ff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;

        
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 7vh;
        height: 52vh;
        width: 55vw;
        left: 8vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 117vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 13vh;
        left: 8.5vw;
        width: 83vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 1.2rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        top: 20vh;
        height: 38vh;
        left: 26.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 5.5rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 2.4rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 4rem;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;

    }

    .bigDispImgBot{
        width: 19% !important;
    }




}




@media (min-width: 900px) and (max-width: 999px){


    .motherDisp{
        box-sizing: border-box;
      
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 786vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 52vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
       
        font-size: 2.4rem;
        text-align: center;
        
        color: #00f7ff;
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 14vw;
        width: 68vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.2rem;
        word-spacing: 0.1rem;
        
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 41vh;
        left: 18vw;
        width: 60vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 26vh;
        left: 12vw;
        width: 72vw;
        height: 18vh;
        text-align: center;
        
        color: #00f7ff;

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
      
        font-size: 2.4rem;
        word-spacing: 0.7rem;
        letter-spacing: -0.10rem;
       
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        bottom: 1vh;
        left: 8vw;
        width: 85vw;
        height: 45vh;
        /* padding-top: 13rem; */
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 1.2rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem !important;
        
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: 5vh;
        height: 52vh;
        width: 56vw;
        left: 8vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 156vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 65vw;
        margin-bottom: 3rem;
        border: 0.15rem solid #00f7ff;
        padding: 3rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem;
        margin: 0;
        word-spacing: 0.2rem;

        
        color: #00f7ff;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem;
        margin: 0;

        
        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 77vw;
        height: 26vh;
        position: relative;
        top: 16vh;
        left: 12.5vw;
        text-align: start;
        
        

    }
    .motoDisp-4 p{
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;

        
        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        top: 7vh;
        height: 52vh;
        width: 56vw;
        left: 8vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 5vh;
        left: 8vw;
        width: 85vw;
        height: 52vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 1.2rem;
    }
    .concernTxt-5{
        display: inline-block !important;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.4rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        bottom: -4vh;
        height: 37vh;
        left: 25vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 5.5rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        
        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 2.4rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    .colUnderMarg{
        margin-bottom: 4rem;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.2rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;

    }

    .bigDispImgBot{
        width: 19% !important;
    }




}



@media (min-width: 1000px) and (max-width: 1199px){
    .bigDispImgBot{
        width: 9% !important;
    }



    .motherDisp{
        box-sizing: border-box;
      
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 730vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 29vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        
        font-size: 1.6rem;
        text-align: center;
        
        color: #00f7ff;
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 42.5vw;
        width: 50vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 0.9rem;
        word-spacing: 0.1rem;
        
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 24vh;
        left: 2vw;
        width: 34vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 22vh;
        left: 32.5vw;
     
        width : 60vw;
        height: 40vh;
        text-align: end;
        
        color: #00f7ff;

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
       
        font-size: 2.6rem;
        word-spacing: 0.7rem;
        letter-spacing: -0.10rem;
        
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 7vh;
        left: 6vw;
        width: 46vw;
        height: 81vh;
        /*padding-top: 13rem;*/
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 0.9rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.2rem !important;
        
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.15rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: 68vh;
        height: 73vh;
        left: 56vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        width: 22vw !important;
        border: 0.11rem solid #00f7ff;
        padding: 2rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.9rem;
        margin: 0;
        word-spacing: 0.2rem;

        
        color: #00f7ff;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.6rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.9rem;
        margin: 0;

        
        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.6rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 48vw;
        height: 81vh;
        position: relative;
        top: 10vh;
        left: 45.5vw;
        text-align: end;
        padding-top: 17%;
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.6rem;

        
        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        bottom: 68vh;
        height: 73vh;
        left: 6vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 7vh;
        left: 6vw;
        width: 55vw;
        height: 81vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 0.9rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.1rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        bottom: 61vh;
        height: 48vh;
        left: 63.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3.5rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        
        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 2.15rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.7rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.7rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.7rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.7rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.7rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.6rem;

    }

    






}



@media (min-width: 1200px) and (max-width: 1365px){
    .bigDispImgBot{
        width: 8% !important;
    }


    .motherDisp{
        box-sizing: border-box;
      
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 730vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 29vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        
        font-size: 2rem;
        text-align: center;
        
        color: #00f7ff;
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 42.5vw;
        width: 50vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1rem;
        word-spacing: 0.1rem;
        
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 23vh;
        left: 3vw;
        width: 29vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 21vh;
        left: 32.5vw;
     
        width : 60vw;
        height: 40vh;
        text-align: end;
        
        color: #00f7ff;

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
       
        font-size: 2.8rem;
        word-spacing: 0.7rem;
        letter-spacing: -0.10rem;
        
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 7vh;
        left: 6vw;
        width: 46vw;
        height: 81vh;
        /*padding-top: 13rem;*/
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 1rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem !important;
        
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.5rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: 67vh;
        height: 73vh;
        left: 59vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        
        border: 0.11rem solid #00f7ff;
        padding: 2rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1rem;
        margin: 0;
        word-spacing: 0.2rem;

        
        color: #00f7ff;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        color: #00f7ff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1rem;
        margin: 0;

        
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 48vw;
        height: 81vh;
        position: relative;
        top: 10vh;
        left: 45.5vw;
        text-align: end;
        padding-top: 17%;
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3rem;

        
        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        bottom: 68vh;
        height: 73vh;
        left: 6vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 7vh;
        left: 6vw;
        width: 55vw;
        height: 81vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 1rem;
    }
    .concernTxt-5{
        display: inline-block !important;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.5rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        bottom: 65vh;
        height: 59vh;
        left: 64.5vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 4.5rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        
        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 2.5rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.7rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.7rem;

    }

    






}



@media (min-width: 1366px) and (max-width: 1919px){
    .bigDispImgBot{
        width: 7% !important;
    }

    .motherDisp{
        box-sizing: border-box;
      
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 730vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 29vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        color: #00f7ff;
        font-size: 2rem;
        text-align: center;
        
        
        
       
    }
    .webName p{
        margin: 0;
       
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 42.5vw;
        width: 50vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1rem;
        word-spacing: 0.1rem;
        
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 18vh;
        left: 3vw;
        width: 28vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 33vh;
        left: 32.5vw;
     
        width : 60vw;
        height: 40vh;
        text-align: end;
        
        color: #00f7ff;

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
    
        font-size: 3rem;
        word-spacing: 0.7rem;
        letter-spacing: -0.10rem;
        
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 7vh;
        left: 6vw;
        width: 54vw;
        height: 81vh;
        /*padding-top: 13rem;*/
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 1rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.5rem !important;
        
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.5rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: 72vh;
        height: 82vh;
        left: 64vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        
        border: 0.11rem solid #00f7ff;
        padding: 2rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1rem;
        margin: 0;
        word-spacing: 0.2rem;
        
        
        color: #00f7ff;


        
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.75rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1rem;
        margin: 0;
        
        color: #00f7ff;
        
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 54vw;
        height: 81vh;
        position: relative;
        top: 10vh;
        left: 39.5vw;
        text-align: end;
        padding-top: 17%;
        

    }
    .motoDisp-4 p{
        
        color: #00f7ff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3rem;

        
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        bottom: 72vh;
        height: 82vh;
        left: 6vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 7vh;
        left: 7vw;
        width: 54vw;
        height: 81vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 1rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.5rem;
        
        color: #00f7ff;

        
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        bottom: 64vh;
        height: 59vh;
        left: 64vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 5rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        
        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 2.5rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.8rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 0.7rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
    }
    .colEightDes2{
        text-align: center;
        color: black;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 0.7rem;

    }

    




      



}



@media (min-width: 1920px) and (max-width: 2559px) {

    .motherDisp{
        box-sizing: border-box;
      
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 730vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;
        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 29vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        color: #00f7ff;
        font-size: 2.8rem;
        text-align: center;
        
       
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 42.5vw;
        width: 50vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.4rem;
        word-spacing: 0.1rem;
        
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 18vh;
        left: 3vw;
        width: 30vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 33vh;
        left: 32.5vw;
     
        width : 60vw;
        height: 40vh;
        text-align: end;
        
        color: #00f7ff;

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        
        font-size: 3.9rem;
        word-spacing: 0.7rem;
        letter-spacing: -0.10rem;
        
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 7vh;
        left: 6vw;
        width: 54vw;
        height: 81vh;
        /*padding-top: 13rem;*/
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 1.4rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.2rem !important;
        
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3.4rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: 74vh;
        height: 82vh;
        left: 63vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        
        border: 0.11rem solid #00f7ff;
        padding: 2.8rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem;
        margin: 0;
        word-spacing: 0.2rem;

        
        color: #00f7ff;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.7rem;
        margin: 0;

        
        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 54vw;
        height: 81vh;
        position: relative;
        top: 10vh;
        left: 39.5vw;
        text-align: end;
        padding-top: 17%;
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3.9rem;

        
        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        bottom: 72vh;
        height: 82vh;
        left: 6vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 7vh;
        left: 7vw;
        width: 54vw;
        height: 81vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 1.4rem;
    }
    .concernTxt-5{
        display: inline-block !important;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 3.4rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        bottom: 64vh;
        height: 59vh;
        left: 64vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 6.8rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        
        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 3.4rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.3rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.3rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.3rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.3rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.3rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }

    input,
    input::placeholder {
        font-size: 1rem;
    }
    textarea,
    textarea::placeholder {
        font-size: 1rem;
    }    


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
        font-size: 2.3rem !important;
    }
    .colEightDes2{
        text-align: center;
        color: black;
        font-size: 1rem;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1rem;

    }

    .bigDispImgBot{
        width: 7% !important;
    }

    


}


@media (min-width:2560px) and (max-width:6000px){

    .motherDisp{
        box-sizing: border-box;
      
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        background-color: black;
        width: 100vw !important;
        height: 730vh !important;
        padding: 0;
        margin: 0;
    }
    .motherDisp::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }


    .firstDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 1rem;

        
    }  


    .webName{
        display: block;
        position : relative;
        top: 5vh;
        left:3vw;
        width : 29vw;
        height : 3vh;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        color: #00f7ff;
        font-size: 3.6rem;
        text-align: center;
        
        
        
       
    }
    .webName p{
        margin: 0;
        
    }


    .navBar{
        position: relative;
        top: 10vh;
        left : 42.5vw;
        width: 50vw;
        height: 4vh;
        
        flex-direction: row;
        column-gap: 1.5rem;
        justify-content: space-evenly;
    }
    .navBar div{
        cursor: pointer;
    }
    .navBar div p{
        margin: 0;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        color: #07c3e9;
        font-size: 1.9rem;
        word-spacing: 0.1rem;
        
    }

    .imgStyle-home{
        display: block;
        position: relative;
        top: 18vh;
        left: 3vw;
        width: 30vw;
    }

    .motoDisp-1{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        bottom: 33vh;
        left: 32.5vw;
     
        width : 60vw;
        height: 40vh;
        text-align: end;
        
        color: #00f7ff;

    }
    .motoDisp-1 p{
        margin: 0;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
      
        font-size: 4.8rem;
        word-spacing: 0.7rem;
        letter-spacing: -0.10rem;
      
    }




    .secondDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }  


    .motoDisp-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 7vh;
        left: 6vw;
        width: 54vw;
        height: 81vh;
        /*padding-top: 13rem;*/
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-2 p{
        font-size: 1.8rem;
    }
    .mernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.8rem !important;
        
        color: #00f7ff;
    }

    .concernTxt{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 4.2rem;
        
        color: #00f7ff;
    }

    .imageStyle-Second{
        display: block;
        position: relative;
        bottom: 72vh;
        height: 82vh;
        left: 62vw;
    }


    .thirdDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
        margin : 0;
        
    }


    .rowWidth{
        width: 75vw;
        justify-content: space-evenly;
    }

    .packageBox{
        
        
        border: 0.11rem solid #00f7ff;
        padding: 3.6rem;
        backface-visibility: hidden;
        cursor: pointer;

    }
    .marginStyle-packNo{
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }
    .packNo{
        text-align: center;
        color: #ffffff;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.1rem;
        margin: 0;

        text-decoration: underline;
        text-decoration-color: #ffffff;
        text-decoration-style: solid;
        text-underline-offset: 0.7rem;
    }
    .packName{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.3rem;
        margin: 0;
        word-spacing: 0.2rem;

        
        color: #00f7ff;
    }

    .packDetails{
        text-align: start;
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.4rem;
        margin: 0;
        word-spacing: 0.2rem;
    }

    .packPrice{
        text-align: center;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 2.3rem;
        margin: 0;

        
        color: #00f7ff;
        
    }

    .packageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.4rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        cursor: pointer;
    }



    .fourthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
        
    }

    .motoDisp-4{
        display: inline-block;
        width: 54vw;
        height: 81vh;
        position: relative;
        top: 10vh;
        left: 40.5vw;
        text-align: end;
        padding-top: 17%;
        

    }
    .motoDisp-4 p{
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 4.8rem;

        
        color: #00f7ff;
    }
    .imageStyle-Fourth{
        display: block;
        position: relative;
        bottom: 72vh;
        height: 82vh;
        left: 5vw;
    }



    .fifthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: block;
        
        position: relative;
        padding: 0;
        margin : 0;
        
       
    }
    .motoDisp-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        position: relative;
        top: 7vh;
        left: 6vw;
        width: 54vw;
        height: 81vh;
        /*padding-top: 7%;
        /* padding-bottom: 7rem; */
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;

    }
    .motoDisp-5 p{
        font-size: 1.8rem;
    }
    .concernTxt-5{
        display: inline-block !important;
       
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 4.2rem;

        
        color: #00f7ff;
    }

    .imageStyle-Third{
        display: block;
        position: relative;
        bottom: 64vh;
        height: 59vh;
        left: 64vw;
    }



    .sixthDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 5rem;
        margin : 0;
        
       
    }
    .sixthDisp p{
        text-align: center;
        
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 8.8rem;
       
        margin: 0;
        padding: 0;
        cursor: pointer;

        
        color: #00f7ff;

    }



    .seventhDisp{
        background-color: black;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    
       
    }
    .contactRow-Just{
        justify-content:space-evenly;
        width: 80vw;
    }
    .colLoc-Details{
        
        padding: 2rem;
        
    }
    .contUs{
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;

        
        color: #00f7ff;
    }
    .colForm-Details{
        
        padding: 2rem;
        
    }
    .textLocDesign{
        color: #ffffff;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 4.2rem;
    }
    .textLocDetails{
        color: #07c3e9;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.9rem;
        padding-left: 1rem;
        border-left: 0.15rem solid #07c3e9;
        margin-top: 1rem !important;
    }

    input[type=text]{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.7rem;

        
    }

    input[type=text]:focus {
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 1rem;
        outline:none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.7rem;
      }

      textarea{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.7rem;

     
      }
      textarea:focus{
        color:#07c3e9;
        background-color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0.1rem solid #07c3e9;
        width: 100%;
        margin-bottom: 0.5rem;
        outline:none;
        resize: none;

        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.7rem;
      }

      .messageButton{
        margin-top: 1.6rem;
        border: none;
        background-color: #00f7ff;
        color: black;
        font-family: "Coda", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: 1.4rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;

        box-shadow: rgba(161, 209, 254, 0.45) 0 8px 18px;
    }

    input,
    input::placeholder {
        font-size: 1.4rem;
    }
    textarea,
    textarea::placeholder {
        font-size: 1.4rem;
    }    


    .eightDisp{
        background-color: #07c3e9;
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        margin : 0;
    }
    .rowEight{
        width: 85vw;
    }
    .colEightDes1{
        text-align: end;
        color: black;
        font-size: 3.3rem !important;
    }
    .colEightDes2{
        text-align: center;
        color: black;
        font-size: 1rem;
    }
    .colEightDes3{
        text-align: center;
        color: black;
        font-family: "Oxanium", sans-serif;
        font-optical-sizing: auto;
        font-weight: 750;
        font-style: normal;
        font-size: 1.4rem;

    }

    .bigDispImgBot{
        width: 7% !important;
    }

    


}

textarea::-webkit-scrollbar {
    width: 0.32em;
    
    
}

textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

textarea::-webkit-scrollbar-thumb {
  background-color: #07c3e9;
  border-radius: 0.2em;
  outline: 1px solid #07c3e9;
}



body::-webkit-scrollbar {
    width: 0.32em;
    background-color: #000000;
    
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #07c3e9;
  border-radius: 0.2em;
  outline: 1px solid #07c3e9;
}


body::-webkit-scrollbar:horizontal {
    height: 0.32em;
    background-color: #000000;
    
}

body::-webkit-scrollbar-track:horizontal {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
body::-webkit-scrollbar-thumb:horizontal {
    background-color: #07c3e9;
    border-radius: 0.2em;
    outline: 1px solid #07c3e9;
  }


  .loader{
    width: 100vw;
    height: 100vh;
  }

  @keyframes glow {
    0% {
        text-shadow:  0 0 9px rgba(202,228,225,0.98),
        0 0 33px rgba(202,228,225,0.1),
        0 0 15px rgba(30,132,242,0.1),
        0 0 25px rgba(30,132,242,0.25),
        0 0 41px rgba(30,132,242,0.3),
        0 0 63px rgba(30,132,242,0.35);
        color: #cce6ff;
    }
    50% {
        
        
        color: #ffffff;
    }
    100% {
        text-shadow:  0 0 9px rgba(202,228,225,0.98),
        0 0 33px rgba(202,228,225,0.1),
        0 0 15px rgba(30,132,242,0.1),
        0 0 25px rgba(30,132,242,0.25),
        0 0 41px rgba(30,132,242,0.3),
        0 0 63px rgba(30,132,242,0.35);
        color: #cce6ff;
    }
  }

  